<template>
 <div id="calc">
   <h2>Выберите сумму займа</h2>
   <input2Anketa :title="'Телефон для связи'" placeHolder="Введите номер телефона" :scrName="'PHONE'" v-model="form.PHONE" :isPhoneMask="true" :error="error.PHONE.hideErr === 0 ? error.PHONE.errMsg : ''" v-on:focus="error.PHONE.hideErr = 1" v-on:blur="error.PHONE.hideErr = 0" ></input2Anketa>
   <input2Anketa :title="'Имя'" :scrName="'FIRST_NAME'" v-model="form.FIRST_NAME" placeHolder="Введите Имя" :error="error.FIRST_NAME.hideErr === 0 ? error.FIRST_NAME.errMsg : ''" v-on:focus="error.FIRST_NAME.hideErr = 1" v-on:blur="error.FIRST_NAME.hideErr = 0" ></input2Anketa>
   <div id="summ">
     <p>Вы получите</p><h2>{{value}} ₽</h2>
   </div>
   <input type="range" min="100000" max="5000000" step="10000" id="slider" v-model="value">
   <div id="fromTo">
     <p>от 100 000 ₽</p>
     <p>до 5 000 000 ₽</p>
   </div>
   <div v-if="backmsgIs" style="text-align: center;" ><span v-html="backmsg"></span></div>
   <button v-on:click="sendData">ПОЛУЧИТЬ ДЕНЬГИ</button>
 </div>
</template>

<script>
import input2Anketa from '/src/components/form/input2Anketa';
//import axios from 'axios';
const defaultErrObj = (defMess = 'Поле обязательное для заполнения') => {
  return  {
    errMsg:defMess,
    hideErr:1
  }
};
export default {
  name: "calc2Avtolanding",
  components: {
    input2Anketa
  },
  props: {
    accepted: {
      type: Boolean,
      default: false
    },
    faked: {
      type: Boolean,
      default: true
    },
    modelValue:{
      type: [String,Number,Object],
      default: '',
    },
  },
  data() {
    return {
      form:{
        summ:250000,
        PHONE:'',
        FIRST_NAME:''
      },
      error: {
        PHONE:defaultErrObj(),
        FIRST_NAME:defaultErrObj()
      },
      formInSendProgress:false,
      backmsgIs:false,
      backmsg:'',
      tiket_send:true,
    }
  },
  computed:{
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.form.summ = value
        this.$emit('update:modelValue', value)
      }
    },
    canSaveForm(){
      let countErr = 0;
      for( let field  in this.error){
        countErr += this.error[field].errMsg.length;
      }
      return countErr === 0;
    }
  },
  watch:{
    form: {
      handler(newValue) {
        for (let indexKey in newValue) {
          switch (indexKey) {
            case 'FIRST_NAME':
                this.error.FIRST_NAME.errMsg = (newValue.FIRST_NAME === '') ? 'поле не должно быть пустым' : '';
              break;
            case 'PHONE':
               this.error.PHONE.errMsg = (newValue.PHONE.length < 18) ? 'номер должен быть заполнен полностью' : '';
               break;
            default: break
          }
        }
      },
      deep: true
    }
  },
  methods: {
    scrollTo() {
      this.$emit('scrollToAnketa', 'anketaSection')
    },
    sendData() {
      //this.$emit('sendData');
      // eslint-disable-next-line no-undef
      //ym(94138632,'reachGoal','email_click')
      if ( this.canSaveForm ?? this.tiket_send ) {
          let vvn = new URLSearchParams();
          vvn.set('summ', this.form.summ);
          vvn.set('FIRST_NAME', this.form.FIRST_NAME);
          vvn.set('PHONE', this.form.PHONE);
          fetch('/api/sendmail.php', {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: vvn
          }).then(response => response.json()).then(data => {
            if(
            typeof data == 'object'
            && typeof data.code == 'number'
            && typeof data.msg == 'string'
            ){
              if( data.code > 0){
                this.backmsgIs = true
                this.backmsg = data.msg
              } else {
                this.backmsgIs = true
                this.backmsg = data.msg
                this.tiket_send = false
                // eslint-disable-next-line no-undef
                ym(94138632,'reachGoal','email_click')
              }
            }
          })
      } else {
        for (let indexKey in this.error){
          this.error[indexKey].hideErr = 0;
        }
        let FormErr = 'Заполните все поля формы';
        if (!this.canSaveForm){
          alert(FormErr);
        }
        this.formInSendProgress = false;
      }
    }
  },
}
</script>

<style scoped>
#slider { -webkit-appearance: none; width: 100%; background: rgba(67, 172, 52, 1); height: 0.56vw; margin-bottom: 2.78vw }
#slider::-webkit-slider-thumb { background: url("/src/assets/svg/iconRange.svg") }
/*#slider::-webkit-slider-thumb { background: red }*/
#slider::-moz-range-thumb { background: url("/src/assets/svg/iconRange.svg") }
/*#slider::-moz-range-thumb { background: red }*/
#calc { box-shadow: 0 1.11vw 8.06vw 0 rgba(0, 0, 0, 0.1); padding: 8.61vw 5.56vw 5.28vw 5.56vw; margin: 21.39vw 5vw 31.11vw 5vw; border-radius: 5.56vw }
#calc>button  { width: 100%; margin-top: 10.28vw }
#calc>h2 { font-family: 'Ubuntu-Bold'; margin: 0 }
#summ { display: flex; align-items: center; justify-content: space-between }
#fromTo { display: flex;  justify-content: space-between; color: rgba(19, 21, 21, 1); font-family: 'Ubuntu-Regular'; opacity: 70% }
#fromTo>p { font-size: 3.33vw }
#submitButton {  transition: ease-out 0.3s }
#submitButton.active { opacity: 100% }
#err { color: red; padding: 0.5vw 1vw }
@media screen and (min-width:760px) {
  #slider { height: 0.15vw; width: 25.95vw; margin-bottom: 0.61vw }
  #slider::-webkit-slider-thumb { background: url("/src/assets/svg/iconRange.svg") }
  /*#slider::-webkit-slider-thumb { background: red }*/
  #slider::-moz-range-thumb { background: url("/src/assets/svg/iconRange.svg") }
  /*#slider::-moz-range-thumb { background: red }*/
  #calc { padding: 0; margin: 0; border-radius: 20px; width: 32.06vw; box-shadow: 0 0.31vw 2.21vw 0 rgba(0, 0, 0, 0.1); display: flex; flex-direction: column; align-items: center; background-color: #FFFFFF }
  #calc>button  { height: 5.19vw; font-size: 1.15vw; border-radius: 15px; width: 26.72vw; margin: 2.90vw 0 2.75vw 0 }
  #calc>h2 { margin: 3.05vw 5.34vw}
  #summ { margin-bottom: 3.21vw }
  #summ>h2 { margin: 0 }
  #summ>p { margin-right: 0.46vw }
  #calc #fromTo { margin-bottom: 2.75vw; width: 27vw }
  #fromTo>p { font-size: 0.92vw }
}
@media screen and (min-width:1200px) {
  #slider { height: 2px; width: 340px; margin-bottom: 8px }
  #slider::-webkit-slider-thumb { background: url("/src/assets/svg/iconRange.svg") }
  /*#slider::-webkit-slider-thumb { background: red }*/
  #slider::-moz-range-thumb { background: url("/src/assets/svg/iconRange.svg") }
  /*#slider::-moz-range-thumb { background: red }*/
  #calc { padding: 0; margin: 0; border-radius: 20px; width: 420px; box-shadow: 0 4px 29px 0 rgba(0, 0, 0, 0.1); display: flex; flex-direction: column; align-items: center; background-color: #FFFFFF }
  #calc>button  { height: 68px; font-size: 15px; border-radius: 15px; width: 350px; margin: 38px 0 36px 0 }
  #calc>h2 { margin: 40px 70px}
  #summ { margin-bottom: 42px }
  #summ>h2 { margin: 0 }
  #summ>p { margin-right: 6px }
  #calc #fromTo { margin-bottom: 36px; width: 343px }
  #fromTo>p { font-size: 12px }
}
</style>
