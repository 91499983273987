<template>
<div class="inputConteiner">
<div class="inputMainBox">
  <div class="title">{{title}}</div>
  <input
      v-if="dadataInpt === false"
      type="text"
      v-model="value"
      v-phoneMask="isPhoneMask"
      v-on:focus="onFocus"
      v-on:blur="onBlur"
      v-bind="$attrs"

  >
  <input
      v-if="dadataInpt === true"
      type="text"
      v-model="value"
      v-bind="$attrs"
      v-on:keydown="keydownEvent"
  >
  <div
      id="suggestions"
      v-if="dadataInpt === true && suggestionsArray.length > 0 && showSuggest"
  >
    <div
        class="suggestion"
        v-bind:class="{active: dadataSuggestionIndex === index }"
        v-on:click="selectSuggestion(item,index)"

        v-for="(item, index) in suggestionsArray" :key="index"
    >{{item.value}}
    </div>
  </div>
  <p class="error" >{{error}}</p>
</div>
</div>
</template>

<script>


export default {
  name: "input2Anketa",
  props: {
    title: {
      type: String,
      default: ''
    },
    dadataInpt: {
      type: Boolean,
      default: false
    },
    modelValue:{
      type: [String,Number],
      default: '',
    },
    isPhoneMask:{
      type:Boolean,
      default:false
    },
    scrName:{
      type:String,
      default:''
    },
    error:{
      type:String,
      default:''
    },
  },
  data() {
    return {
      valueDadata: '',
      suggestionsArray: [],
      dadataSuggestionIndex: 0,
      showSuggest:false
    }
  },
  methods:{
    keydownEvent($event) {
      if (this.suggestionsArray.length !== 0) {
        switch ($event.code) {
          case 'ArrowDown':
            this.dadataSuggestionIndex += (this.dadataSuggestionIndex === (this.suggestionsArray.length - 1)) ?
              0 : 1;
            break;
          case 'ArrowUp':
            this.dadataSuggestionIndex -= (this.dadataSuggestionIndex === 0)? 0 : 1;
            break;
          case 'Enter':
            this.value = this.suggestionsArray[this.dadataSuggestionIndex].value;
            this.$parent.form.AddressDaData = this.suggestionsArray[this.dadataSuggestionIndex].data;
            this.showSuggest = false;
            break;
        }
      }
    },
    selectSuggestion(value,index) {
      this.dadataSuggestionIndex = index;
      this.value = value.value;
      this.$parent.form.AddressDaData = value.data;
      // this.suggestionsArray = []
      this.showSuggest = false;
    },
    onFocus(){
      this.$ApiHandler.singleRequest({
        action:'trackInputFocus',
        key:this.scrName,
        value:this.value,
      }).then((res)=>{
        res['noLintError']='';
      }).catch((res)=>{
        res['noLintError']='';
      })
    },
    onBlur(){
      this.$ApiHandler.singleRequest({
        action:'trackInputBlur',
        key:this.scrName,
        value:this.value,
      }).then((res)=>{
        res['noLintError']='';
      }).catch((res)=>{
        res['noLintError']='';
      })
    }
  },
  watch: {
    value(value) {
      if (this.dadataInpt) {
        if (value.length > 2) {
          if (this.suggestionsArray.length > 0 && value === this.suggestionsArray[this.dadataSuggestionIndex].value){
            return false
          }
          this.dadataSuggestionIndex = 0;
          this.$ApiHandler.singleRequest({
            action: 'dadata',
            value: value
          }).then((res)=>{
            this.suggestionsArray = res.suggestions;
            this.dadataSuggestionIndex = 0;
            this.showSuggest = true;
          })
        }
      }
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
.title { font-size: 2.78vw; color: rgba(134, 154, 149, 1); font-family: 'Ubuntu-Regular' }
.inputConteiner{ padding: 0 1.22vw; box-sizing: border-box; width: 100%; }
.inputMainBox { border: 1px solid #D5D5D5; border-radius: 10px; padding: 4.17vw 5.56vw; margin-bottom: 4.44vw; position: relative; width: 100%; box-sizing: border-box; }
p { font-family: 'Ubuntu-Regular'; font-size: 3.33vw; color: #959595 }
input { width: calc(100% - 2vw); border: none; font-family: 'Ubuntu-Regular'; font-size: 3.33vw; color: #959595}
.error { font-size: 3vw; color: red}
#suggestions { position: absolute; top: 3vw; left: 2vw; background-color: #FFFFFF; border: 1px solid #D5D5D5; border-radius: 10px; padding: 1vw; z-index: 10}
.suggestion:hover { background-color: rgba(75, 175, 50, 0.39)}
.suggestion.active { background-color: rgba(75, 175, 50, 0.39)}
.suggestion { opacity: 75%; cursor: pointer }
@media screen and (min-width:760px) {
  .title { font-size: 1vw }
  .inputMainBox { padding: 0.84vw 1.53vw; margin-bottom: 1.22vw; border-radius: 0.76vw }
  p {  }
  input { font-size: 1.3vw }
  .error { font-size: 1.15vw; color: red}
  #suggestions { top: 3.13vw; left: 2.29vw; padding: 1.15vw }
  .suggestion:hover { }
  .suggestion { }
}
@media screen and (min-width:1200px) {
  .title { font-size: 10px }
  .inputMainBox { padding: 11px 20px; margin-bottom: 16px; border-radius: 10px }
  p {  }
  input { font-size: 14px }
  .error { font-size: 15px; color: red}
  #suggestions { top: 41px; left: 30px; padding: 15px }
  .suggestion:hover { }
  .suggestion { }
}
</style>
