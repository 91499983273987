<template>
  <homePage></homePage>
</template>

<script>
import homePage from './components/homePage'
export default {
  name: 'App',
  components:{
    homePage
  }
}
</script>

<style>
/** {border: red 1px solid}*/

input { margin: 0 2.22vw 0 0 }
html,body { font-family: "Lato-Regular"; margin: 0; color: #131515 }
h1 { font-size: 6.67vw; font-family: 'Ubuntu-Regular'; line-height: 11.67vw }
h2 { font-size: 5vw; font-family: 'Ubuntu-Bold' }
h3 { font-size: 5vw; margin: 0 }
p { margin: 0; font-size: 4.44vw }
@font-face {  font-style: normal;  font-family: "Ubuntu-Regular";  src: local("Ubuntu-Regular"), url('./assets/fonts/Ubuntu-Regular.woff') format("woff"), url('./assets/fonts/Ubuntu-Regular.woff2') format("woff2"), url('./assets/fonts/Ubuntu-Regular.ttf') format("truetype")}
@font-face {  font-style: normal;  font-family: "Ubuntu-Bold";  src: local("Ubuntu-Bold"), url('./assets/fonts/Ubuntu-Bold.woff') format("woff"), url('./assets/fonts/Ubuntu-Bold.woff2') format("woff2"), url('./assets/fonts/Ubuntu-Bold.ttf') format("truetype")}
section {}
@font-face {  font-style: normal;  font-family: "Lato-Regular";  src: local("Lato-Regular"), url('./assets/fonts/Lato-Regular.woff') format("woff"), url('./assets/fonts/Lato-Regular.woff2') format("woff2"), url('./assets/fonts/Lato-Regular.ttf') format("truetype")}
@font-face {  font-style: normal;  font-family: "Lato-Heavy";  src: local("Lato-Heavy"), url('./assets/fonts/Lato-Heavy.woff') format("woff"), url('./assets/fonts/Lato-Heavy.woff2') format("woff2"), url('./assets/fonts/Lato-Heavy.ttf') format("truetype")}
@font-face {  font-style: normal;  font-family: "Lato-Bold";  src: local("Lato-Bold"), url('./assets/fonts/Lato-Bold.woff') format("woff"), url('./assets/fonts/Lato-Bold.woff2') format("woff2"), url('./assets/fonts/Lato-Bold.ttf') format("truetype")}
@font-face {  font-style: normal;  font-family: "Lato-Black";  src: local("Lato-Black"), url('./assets/fonts/Lato-Black.woff') format("woff"), url('./assets/fonts/Lato-Black.woff2') format("woff2"), url('./assets/fonts/Lato-Black.ttf') format("truetype")}
button { height: 13.89vw; width: 58.06vw; border-radius: 4.17vw; background-color: #4BAF32; border: none; font-size: 4.44vw; color: white }

@media screen and (min-width: 760px) {
  input { margin: 0 }
  p { font-size: 1.22vw }
  h1 { font-size: 2.90vw; line-height: 2.67vw }
  h2 { font-size: 1.83vw }
  h3 { font-size: 1.37vw }
  button { width: 26.72vw; height: 5.19vw; border-radius: 15px; font-size: 1.15vw; cursor: pointer }
}

@media screen and (min-width: 1200px) {
  section {}
  html,body { }
  h1 { font-size: 38px; font-family: 'Ubuntu-Bold'; line-height: 35px }
  h2 { font-size: 24px; font-family: 'Ubuntu-Bold' }
  h3 { font-size: 18px; font-family: 'Ubuntu-Bold'; margin: 0 }
  p { margin: 0; font-size: 16px }
  button { width: 350px; height: 68px; border-radius: 15px; font-size: 15px; cursor: pointer}
}
</style>
