import { createApp } from 'vue';
import App from './App.vue';
import InlineSvg from 'vue-inline-svg';
import phoneMask from './phoneMask'
import ApiHandler from "./ApiHandler";
import VueJivosite from '@bchteam/vue-jivosite';
//import VueYandexMetrika from 'vue-yandex-metrika';
const app = createApp(App);
app.component('inline-svg', InlineSvg);
app.directive('phoneMask',phoneMask);
app.config.globalProperties.$ApiHandler = ApiHandler;
app.mount('#app');
app.use(VueJivosite, { widgetId: 'gnUKRR1iDP' })
/*
app.use(VueYandexMetrika, {
    id: 94138632,
	ignoreRoutes:true,
    //scriptSrc: 'https://cdn.jsdelivr.net/npm/yandex-metrica-watch/tag.js',
	debug:true,
    env: process.env.NODE_ENV,
    // other options
	options:{
		clickmap:true,
		trackLinks:true,
		accurateTrackBounce:true,
		webvisor:true,
		trackHash:true
	},
	triggerEvent: true,
})
*/