
const phoneMask ={
  // called before bound element's attributes
  // or event listeners are applied
  // created(el, binding, vnode, prevVnode) {
  created(el,binding) {
    // console.log(el, binding, vnode)
  // see below for details on arguments
    if (binding.value){
      el.oninput = function (e) {
        if (!e.isTrusted) return;
        if (e.inputType === 'insertText' || e.inputType==='insertFromPaste') {
          const x = this.value.replace(/\D/g,'').match(/(\d{0,1})(\d{0,10})$/);

          let out = '+';
          if (x[1]==='' || x[1]==='7' || x[2].length === 10) {
            out += '7 (';
          } else {
            out += '7 ('+x[1]
          }

          let i = 0;
          while(i < x[2].length){
            if(i === 2 || i === 5 || i === 7){
              out += (i === 2) ? x[2][i] + ') ' : x[2][i] + '-';
            } else {
              out += x[2][i];
            }
            i+=1;
          }
          this.value = out;
          const eup = document.createEvent('HTMLEvents');
          eup.initEvent('input', true, true);
          el.dispatchEvent(eup);
        }
      }
    }

  },
  // // called right before the element is inserted into the DOM.
  // beforeMount(el, binding, vnode, prevVnode) {
  //   console.log('beforeMount')
  // },
  // // called when the bound element's parent component
  // // and all its children are mounted.
  // mounted(el, binding, vnode, prevVnode) {
  //   console.log('mounted')
  // },
  // // called before the parent component is updated
  // beforeUpdate(el, binding, vnode, prevVnode) {
  // beforeUpdate(el) {
  //   console.log('beforeUpdate');
  //   // console.log(el, binding, vnode, prevVnode);
  // },
  // // called after the parent component and
  // // all of its children have updated
  // updated(el, binding, vnode, prevVnode) {
  //   console.log('updated')
  //   console.log(el, binding, vnode, prevVnode)
  // },
  // // called before the parent component is unmounted
  // beforeUnmount(el, binding, vnode, prevVnode) {
  //   console.log('beforeUnmount')
  // },
  // // called when the parent component is unmounted
  // unmounted(el, binding, vnode, prevVnode) {
  //   console.log('unmounted')
  // }
};

export default phoneMask;
