import axios from 'axios';


export default {
  test:'asd',
  url: function () {
    return process.env.VUE_APP_API_URL;
  },
  sessionId: function (){
    return (window.avtoSession === undefined) ? '1' : window.avtoSession;
  },
  Partner_Id:function(){
    return (window.Partner_Id === undefined) ? '404' : window.Partner_Id;
  },
  checkExecutionErrorVue: function (response) {
    return response['Response']['ExecutionError']['ExecutionErrorCode'] === 0;
  },
  checkErrorCodeVue: function (response) {
    if (response === null) {
      //что то отъебнулось закрываем запрос если повезло с null
      return {result: false, reload: false};
    }
    if (response.Error['ErrorCode'] < 0) {
      // ошибки из фракции полный пипец
      return {result: false, reload: false};
    }
    if (response.Error['ErrorCode'] > 0) {
      // ошибки которые могут пройти если немного подождать
      return {result: true, reload: true};
    }
    if (response.Error['ErrorCode'] === 0) {
      // все збс
      let responeResult = true;
      if (Object.prototype.hasOwnProperty.call(response, "Response")) {
        responeResult = this.checkExecutionErrorVue(response);
      }
      if (responeResult) {
        return {result: true, reload: false};
      } else {
        return {result: false, reload: false};
      }
    }
    return {result: false, reload: false}
  },
  SendRequest: function (sendData,serviceName = '') {
    return new Promise((resolve, reject) => {
      let self = this;

      sendData['avtoSession'] = this.sessionId();
      sendData['Partner_Id'] = this.Partner_Id();
      if (serviceName  != '') {
        sendData['sys_name'] = serviceName;
      }

      axios({
        method: 'post',
        url: this.url(),
        data: sendData,
        responseType: 'json',
        headers: {'Content-type': 'application/json'}
      }).then(function (response) {
        // debugger;
        let checkResult = self.checkErrorCodeVue(response.data);
        if (checkResult.result) {
          if (checkResult.reload) {
            const wait = ms => new Promise(resolve => setTimeout(resolve, ms));
            wait(1000).then(() => {
              self.SendRequest(sendData)
              .then((res) => {
                resolve(res)
              })
              .catch((res) => {
                reject(res)
              });
            });
          } else {
            try {
              serviceName = (response.data.sys_name != undefined) ?  response.data.sys_name : 'x';
            }
            catch (e) {
              serviceName = 'x';
            }


            self.GetRequestResult(response.data['RequestID'],serviceName)
            .then((res) => {
              resolve(res)
            })
            .catch((res) => {
              reject(res)
            });
          }
        } else {
          reject(response.data);
        }
      }).catch(error => {
        reject(error);
      });
    });
  },
  GetRequestResult: function (ExecuteGUID, serviceName = '') {

    return new Promise((resolve, reject) => {
      let self = this;
      let sendData = {
          ExecuteGUID: ExecuteGUID,
          action: 'iks_GetQuery',
          avtoSession:this.sessionId()
        };
      if (serviceName  != '') {
        sendData['sys_name'] = serviceName;
      }
      axios({
        method: 'post',
        url: this.url(),
        data: sendData,
        responseType: 'json',
        headers: {'Content-type': 'application/json'}
      }).then(function (response) {
        let checkResult = self.checkErrorCodeVue(response.data, 'getResultSaveForm');
        if (checkResult.result) {
          if (checkResult.reload) {
            const wait = ms => new Promise(resolve => setTimeout(resolve, ms));
            wait(2000).then(() => {
              self.GetRequestResult(ExecuteGUID, serviceName)
              .then((res) => {
                resolve(res);
              })
              .catch((res) => {
                reject(res)
              });
            });
          } else {
            resolve(response.data['Response']['ExecutionResult']);
          }
        } else {
          reject(response.data);
        }
      }).catch((resp) => {
        if (resp.data !== undefined) {
          reject(resp.data);
        } else {
          reject(resp);
        }
      });
    });
  },
  singleRequest: function (sendData) {
    sendData['avtoSession'] =this.sessionId();
    sendData['Partner_Id'] = this.Partner_Id();
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: this.url(),
        data: sendData
      }).then((resp) => {
        try {
          if (resp.data.Response.ExecutionResult !== undefined) {
            resolve(resp.data.Response.ExecutionResult);
          } else {
            reject(resp.data);
          }
        } catch (resp) {
          reject(resp.data);
        }
      }).catch((resp) => {
        if (resp.data !== undefined) {
          reject(resp.data);
        } else {
          reject(resp);
        }
      });
    });
  },
  catchRequestsChecking: function(result,action='') {
    try {
      if (result.Response.ExecutionError.ExecutionErrorCode > 0) {
        if (result.Response.ExecutionError.ExecutionErrorCode !== 401) {
          alert(result.Response.ExecutionError.ExecutionErrorMessage);
        }
      } else {
        let whoisit =  (result.method != undefined) ?  result.method : action;
        alert('Ошибка, sessionID=' + this.sessionId()
            + ' action=' + whoisit
            + ' ExecutionErrorCode=' + result.Response.ExecutionError.ExecutionErrorCode);
      }
    } catch (e) {
      alert(e)
    }
  }
}
